.marker{
	position: absolute;
	top: 50%;
	left: 50%;

	width: 34px;
	height: 25px;

	background-size: cover !important;
	cursor: pointer;

	transform: translate(-50%, -50%);

	&:hover{
		filter: brightness(125%);
		transition: 0.25s ease;
	}
}
#blue.marker{
	background-image: url(../../static/images/icons/icon-marker-blue.svg);

}
#red.marker{
	background-image: url(../../static/images/icons/icon-marker-red.svg);
}
.small{
	position: relative;

	width: 34px;
	height: 25px;

	top: 0;
	left: 0;

	background-size: cover !important;
	cursor: pointer;

	transform: translate(0%, 0%);

	&:hover{
		filter: brightness(125%);
		transition: 0.25s ease;
	}

}
