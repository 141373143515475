/* IconGraphic styles */
.IconGraphic {
  font-size: 16px;
  line-height: 22px;

  color: #333;
}
.IconIcon {
  height: 84px;
}

/* ------------------------- */

.svg1 {
  padding: 0vw;
  width: 20vw;
}
.svg1m {
  padding: 0vw;
  width: 60vw;
}
.svg2 {
  padding: 0vw;
  width: 13vw;
}
.svg2m {
  padding: 0vw;
  width: 39vw;
}
.svg3 {
  padding: 0vw;
  width: 22vw;
}
.svg3m {
  padding: 0vw;
  width:65vw;
}
.firstslidebg{
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,125,135,1) 75%, rgba(150,41,50,1) 100%);
}
.secondslidebg{
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(91,128,223,1) 75%, rgba(32,54,108,1) 100%);
}
