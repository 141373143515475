@media screen and (max-width: 450px){
    body{
        font-size: 14px;
        line-height: 20px;
    }

    /* Bootstrap styles */
    .container{
        padding-left: 30px;
        padding-right: 30px;
    }

    /* Button styles */
    .button{
        width: 220px;
        line-height: 50px;
    }
    .button::before{
        border: 1px solid #fff;
    }


	/* Header styles */
	.logo{
		height: 30px;
		margin: 3px 0px;
	}
	.newicon{
		height: 36px;
		width: 43px;
	}

    /* Reassign margins */
    .MainNavigation{
        .margin-left-20{
            margin-left: 0px;
        }
        .margin-right-20{
            margin-right: 0px;
        }
        .margin-right-10{
            margin-right: 5px;
        }
    }
	.margin-right-mobile-10{
		margin-right: 10px !important;
	}
    .margin-top-20-mobile{
        margin-top: 20px;
    }
    .margin-bottom-mobile-30{
		margin-bottom: 30px;
	}
	.margin-top-50-mobile{
		margin-top: 50px;
	}
	.padding-right-10{
		padding-right: 5px;
	}
    /* Some paddings */
    .padding-15-mobile{
        padding: 15px;
    }

    /* Benefits styles */
    .IconIcon{
        height: 72px;
    }

    /* News styles */
	.NewsBlock{
		.col-12{
			margin-bottom: 50px;
		}
	}

	/* Contacts styles */
	.ContactInfo{
		.col-xs{
			.row{
				padding-bottom: 10px;
			}
		}
	}
	.ContactPhone{
		font-size: 24px;
		line-height: 30px;
	}

    /* Sibebar styles */
    .sidenav{
        width: 300px;

        .SidebarMenu{
    		a.nav-link{
    			font-size: 14px;
            }
        }
    }

    /* language switcher */
    .dropdown-menu{
        transform: translate3d(-15px, 24px, 0px);
    }

    /* footer */
    footer{
        bottom: -86px;
    }


    /* search tables */
    .search-input-custom{
        width: 250px;
    }

	.search-form-slide{
		position: absolute;
		left: -400px;
		top: 103px;

		width: 250px;
		transition: 0.5s;

		animation: ease-in-out fade-out 0.3s;

		.newicon{
			height: 50px;
			width: 60px;
		}
	}
	.mainpage-map{
		margin-top: 30px;
		height: 300px;
	}
	.padding-left-70{
		padding-left: 0;
	}
	.Slider{
		.row{
			margin-left: 0;
			margin-right: 0;
		}
		padding: 0;
		.container{
			padding: 0;
		}
	}
	.SliderBackground{
		background: #f8f8f8;
		padding: 70px 30px;
	}
	.news{
		margin-top: 50px;
		margin-bottom: 50px;
	}
	.news-block{
		padding-left: 0px;
		padding-right: 0px;
	}

	.ContactBlock{
		margin-bottom: 50px;
	}
}
