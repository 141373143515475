/* TopNavigation styles */
.TopNavigation {
  .nav {
    a.nav-link {
      color: inherit;
      padding: 10px;
    }
    a.nav-link:hover {
      color: white;
      background: #b11622;
      transition: 0.1s ease;
    }
  }
  .TopNavigation-Hover {
    padding: 10px 15px;

    transition: 0.1s ease;
    &:hover {
      background: #fff;
    }
    &:hover p {
      color: black;
    }
  }
}
/* ------------------------ */

.button_logout {
  margin-left: 10%;
  button {
    margin-top: 4%;
    background: #b11622;
    color: white;
    padding: 0.4rem 1rem;
    border: 1px solid #b11622;
    transition: 0.5s;
    &:hover {
      background: white;
      color: #b11622;
      border: 1px solid #b11622;
    }
  }
}

/* MainNavigation styles */
.MainNavigation {
  padding: 15px 0px;
  margin-bottom: 20px;

  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;

  a {
    &:hover {
      color: #b11622;
    }
  }

  .hover-icon-text:hover .profile {
    background-image: url(../static/images/icons/hovered/icon-profile.svg);
  }

  .hover-icon-text:hover .cart {
    background-image: url(../static/images/icons/hovered/icon-cart.svg);
  }

  .hover-icon-text:hover .cart-count {
    border: 1px solid #962932;
    color: #962932;
    background-color: #fff;
  }
}
/* ------------------------ */

/* Cart styles */
.newicon.cart {
  position: relative;
}

.cart-count {
  position: absolute;
  right: 0;
  display: grid;
  place-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  background-color: #962932;
  transition: 0.5s;
}
/* ------------------------ */
