.profilePage {
  width: 100%;
  display: flex;
  flex-direction: row;
  .navigBreadCrumbs{
    margin-left: 3%;
  }
  .profileHeader {
    flex: 1;
    h2 {
      text-transform: uppercase;
      margin-left: 6%;
    }
  }

  .container {
    flex: 5;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .row-item{
      display: flex;
      column-gap: 1%;
      .item1{
        flex-basis: 49.5%;
      }
    }
  }
}
