.dropdown{
    .dropdown-toggle{
        background: none !important;
        border: none !important;
        box-shadow: none !important;

        color: #962932 !important;
        font-weight: bold;

        padding: 0;

        &:hover, &:active, &:focus{
            color: #20366C !important;
        }
    }
    .dropdown-menu{
    	min-width: auto;

        .dropdown-item:hover{
        	background: #f2f2f2 !important;
        }
        .dropdown-item:focus{
            background: #B11622 !important;
        }
    }
}

.sidenav{
    .dropdown{
        .dropdown-toggle{
            color: white !important;
            &:hover, &:active, &:focus{
                color: white !important;
            }
        }
    }

}
