/* NewsBlock styles */
.NewsBlock {
	img{
		margin-bottom: 15px;
		width: 100%;
	}
	h3{
		font-size: 20px;
		line-height: 24px;

		font-weight: 600;
		margin-bottom: 5px;
	}
	p{
		font-size: 14px;
		line-height: 18px;

		color: #666;
	}
}
