.table-responsive > table {
  margin: 20px 0;
}

.document-table,
.products-table {
  thead th,
  td {
    vertical-align: middle;
  }
  .custom-popover {
    padding: 0.75rem;
    cursor: pointer;
  }

  // table-layout: fixed;
  // width: 100%;

  // tbody td,
  // thead th {
  //   white-space: normal;
  //   vertical-align: middle;
  //   word-wrap: break-word;
  // }
  // без фіксед работать не будет
  // .comment {
  //   white-space: normal;
  //   vertical-align: middle;
  //   word-wrap: break-word;
  //   width: 200px;
  // }
  .word-wrap {
    white-space: normal;
    word-wrap: break-word;
  }
  .comment {
    max-width: 200px;
    overflow-x: hidden;
    text-decoration: underline;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
}

.profile-orders {
  .order-number-link {
    color: var(--string-blue);
    cursor: pointer;
  }

  .order-number-link:hover {
    color: var(--main-red);
    cursor: pointer;
  }

  .table-head-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 15px;
    cursor: help;
  }

  .dropdown {
    height: 100%;
  }

  .dropdown-menu {
    border-radius: 0;
  }

  .dropdown-toggle.dropdown-toggle-split {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: inherit !important;
  }

  .dropdown-toggle.dropdown-toggle-split:hover {
    color: var(--main-red) !important;
  }

  .dropdown-toggle.dropdown-toggle-split::after {
    display: none;
  }

  .dropdown-menu .dropdown-item:hover,
  .dropdown-menu .dropdown-item:active,
  .dropdown-menu .dropdown-item:focus {
    background: #f2f2f2 !important;
    color: inherit !important;
  }

  .bi-gear-fill,
  .bi-gear {
    width: 20px;
    height: 20px;
  }

  .page-link {
    color: inherit;
  }

  .page-item.active .page-link {
    border-color: var(--main-red);
    background-color: var(--main-red);
  }

  .page-item:first-child .page-link {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .page-item:last-child .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .product-orders-link button,
  .product-orders-link button:focus {
    text-decoration: underline;
    font-weight: normal;
    color: var(--string-blue) !important;
  }

  .product-orders-link button:hover,
  .product-orders-link button:active {
    color: var(--main-red) !important;
  }

  .product-options-btn,
  .product-options-btn:focus {
    width: 100%;
    box-shadow: none;
    color: inherit;
  }

  .product-options-btn:hover,
  .product-options-btn:active {
    color: var(--main-red) !important;
  }

  .selection-by {
    margin-left: 0.75rem;
    color: var(--string-blue);
    cursor: pointer;
  }
}

.info-modal.product-options {
  .btn-group {
    display: block;
  }

  .radio-group-btn,
  .radio-group-btn:focus {
    display: block;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
    border: 1px solid var(--border);
    color: #1a1414;
  }

  .radio-group-btn:active,
  .radio-group-btn:hover {
    background-color: transparent;
    border: 1px solid var(--main-red);
    color: var(--main-red);
    // opacity: 0.9;
  }

  .radio-group-btn.active {
    background-color: var(--main-red);
    border: 1px solid var(--main-red);
    color: #fff;
  }

  .radio-group-btn input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
}

.download_btn {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: var(--string-blue);
}

.download_btn:hover {
  color: var(--main-red);
}

.download_btn + .download_btn {
  margin-left: 10px;
}

.link-not-hover a:hover {
  color: var(--string-blue);
}