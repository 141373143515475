/* Slider styles */
.Slider{
	border-bottom: 1px solid #962932;
}
.SliderBackground{
	.SliderHeading{
		font-size: 36px;
		line-height: 42px;
		text-transform: uppercase;

		max-width: 450px;
	}
	.line{
		max-width: 350px;
		background: #cdcbcb;
		height: 1px;

		margin: 15px 0px;
	}

	p.SliderText{
		font-size: 16px;
		line-height: 24px;

		margin-bottom: 20px;
		width: 100%;
	}
	.slick-slide{
		div{
			outline: none;
		}
	}
	.slick-active{
		.SliderHeading, p.SliderText, button{
			animation: slider 1s ease-in-out;
		}
	}
	@keyframes slider {
		0% {
	        transform: scale(0.85);
	    }
	    100% {
	        transform: scale(1);
	    }
	}
}
.news-block{
	.row{
		padding-top: 15px;
		padding-bottom: 15px;
		padding-left: 20px;
		padding-right: 20px;
		border-bottom: 1px solid #cdcbcb;
	}
	.row:first-child{
		border-top: 1px solid #cdcbcb;
	}
	.row:hover{
		background: #f8f8f8;
		transition: 0.2s ease;
	}
	.news-headline{
		h3{
			font-size: 14px;
			line-height: 18px;
		}
		p{
			font-size: 14px;
			line-height: 18px;
		}
	}
}

/* ------------------------ */
.main-slider{
	height: 500px;

	.slick-list{
		height: 100%;

		.slick-track{
			height: 100%;
			.slick-slide{
				div{
					div{
						.row{
							height: 100%;
							.col{
								align-self: center;
							}
						}
					}
				}
			}
		}

	}
}
.slick-slide > div{
	height: 100%;
}
.morph-wrap {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
}

.morph {
	position: relative;
	height: 100%;
	fill: "#962932";
	flex: none;
}