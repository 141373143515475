:root {
  --main-red: #962932;
  --main-red-rgb: rgb(150, 41, 50);
  --main-red-rgb-op-4: rgba(150, 41, 50, 0.4);
  --main-blue: #20366c;
  --string-blue: blue;
  --border: #dee2e6;
}

html {
  position: relative;
  min-height: 100%;
}
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Qanelas';
  font-weight: normal;

  font-size: 16px;
  line-height: 24px;

  color: #1a1414;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header,
footer {
  font-size: 14px;
  line-height: 16px;
}

/* font styles*/
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0px;
}
h1,
h2,
h3 {
  font-weight: 700;
}
h4,
h5 {
  font-weight: 600;
}
h1 {
  font-size: 36px;
  line-height: 48px;
}
h2 {
  font-size: 24px;
  line-height: 30px;
}
h3 {
  font-size: 18px;
  line-height: 24px;
}
h4 {
  font-size: 16px;
  line-height: 18px;
}
h5 {
  font-size: 14px;
  line-height: 16px;
}
p {
  color: #333;
}
.font-medium {
  font-weight: 500;
}
.color-red {
  color: #962932;
}
.bold-16px {
  font-size: 16px;
  line-height: 18px;

  font-weight: bold;
}
a {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: #b11622;
    text-decoration: none;

    transition: 0.1s ease;
  }
}
/* ------------------------ */

/* bootstrap corrections */
.form-group {
  margin: 0px;
}
.container {
  max-width: 1366px;

  padding-left: 30px;
  padding-right: 30px;
}
.no-margin-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
/* ------------------------ */

/* Button styles */
.button {
  display: inline-block;

  text-align: center;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;

  line-height: 50px;
  width: 240px;
  margin-left: 15px;

  color: white;

  background: none;
  outline: none;
  border: none;
  cursor: pointer;

  position: relative;
  z-index: 1;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: linear-gradient(to right, #b11622 50%, #962932 50%);
    background-size: 200% 100%;
    background-position: right bottom;

    transition: all 0.3s ease;
    transform: skew(-150deg);
    z-index: -1;
  }
  &:hover::before {
    background-position: left bottom;
  }
  &:focus {
    outline: none;
  }
}
.z-index-1 {
  z-index: 1;
}
.HR {
  position: absolute;

  width: 100%;
  height: 1px;

  background: #dfe9eb;
}
.max-width-300 {
  max-width: 300px;
}
.white {
  color: white;
}
.SearchMobile {
  position: absolute;

  top: 65px;
  right: 25px;

  z-index: 3;
}

div.DraftEditor-root {
  border: 1px solid #000;
  background-color: white;
  height: 200px;
  width: 100%;
  overflow-y: auto;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
}
/// dialog size
.modal-dialog {
  max-width: 400px;
}

///// form bootstrap
.form-control,
.form-control.custom-select,
.form-control:focus {
  border-color: #962932 !important;
  box-shadow: 0px 0px 0px 0px #bf0b0b !important;
}

.custom-select {
  border-color: #962932 !important;
  box-shadow: 0px 0px 0px 0px #bf0b0b !important;
}

/* Lightbox */
.yarl__container {
  background-color: rgba(255, 255, 255, 1) !important;
  .yarl__button {
    color: var(--main-red-rgb) !important;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3)) !important;
  }
  .yarl__button:disabled {
    color: var(--main-red-rgb-op-4) !important;
  }
}
.yarl__thumbnails_container {
  background-color: #f2f2f2 !important;
  .yarl__thumbnails_thumbnail {
    background: transparent !important;
    border: 1px solid #dee2e6 !important;
  }
  .yarl__thumbnails_vignette {
    background: transparent !important;
  }
}

/* Utils */
.c-main-red {
  color: var(--main-red);
}

.c-string-blue {
  color: var(--string-blue);
}

.text-d-underline {
  text-decoration: underline;
}

.btn-main {
  background-color: var(--main-red);
  border-color: var(--main-red);
  border-radius: 0;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible,
  &:disabled {
    background-color: white !important;
    border-color: var(--main-red) !important;
    color: var(--main-red) !important;
    box-shadow: none !important;
  }
}

.btn-second {
  background-color: var(--main-blue);
  border-color: var(--main-blue);
  border-radius: 0;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible,
  &:disabled {
    background-color: white !important;
    border-color: var(--main-blue) !important;
    color: var(--main-blue) !important;
    box-shadow: none !important;
  }
}

[data-reach-combobox-popover] {
  max-height: 300px;
  overflow-y: auto;
}
