.MainNavigation{
	.form-control{
		height: 35px;
		font-size: 12px;

		border: 1px solid #e2e2e2;
		border-radius: 0px;

		width: 320px;
		z-index: 0;
	}
	.search-input-button{
		position: absolute;
		right: -15px;
		top: -7.5px;

		z-index: 4;
	}
}
.search-history-icon{
	width: 16px;
	height: 16px;

	margin: 9.5px 0px;

	background: url(../../static/images/icons/icon-history.svg);
	background-size: cover;

	border-radius: 99px;

	position: absolute;
	right: 50px;

	cursor: pointer;
	transition: 0.2s ease-out;

	&:hover{
		filter: invert(100%);
	}

	z-index: 5;
}

.search-history{
	position: absolute;

	top: 34px;
	left: 0;

	background: #fff;

	border: 1px solid #e2e2e2;

	width: 285px;
	z-index: 5;

	li{
		list-style: none;
		padding: 8px 25px;
		&:hover{
			background: #f8f8f8;
			color: #b11622;
			transition: 0.1s ease;
			cursor: pointer;
		}
	}

}


table tbody td {
	white-space: nowrap;
}