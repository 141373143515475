.ishovered:hover {
  cursor: pointer;

  & button.btn-link {
    text-decoration: underline;
  }
}

.icon-modal-btn {
  font-size: 0;
  color: var(--main-red);
  &:hover,
  &:active,
  &:focus {
    color: var(--main-blue);
    box-shadow: none !important;
  }
}

.info-modal .modal-content {
  border-radius: 0;
  font-size: 14px;
  line-height: 20px;
}

.info-modal_header {
  font-size: 16px;
  font-weight: bold;
}

.info-modal_grid-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
}

.search-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.add_to_cart_btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  margin-left: 6px;
  border: none;
  background-color: transparent;
  transition: 0.5s;

  &:focus,
  &:active,
  &:hover,
  &:disabled {
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  &:active:enabled {
    transform: scale(1.2);
  }

  & svg {
    fill: var(--main-red);
    transition: 0.5s;
  }

  &:focus:enabled svg,
  &:active:enabled svg,
  &:hover:enabled svg {
    fill: var(--main-blue);
  }

  & svg.disable {
    opacity: 0.3;
  }
}

.add_to_cart_input {
  width: 60px;
  text-align: center;
  margin-right: 6px;
}

.icon-plus,
.icon-minus {
  width: 16px;
  height: 16px;
}

.toast-addtocart {
  border-radius: 0;
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);

  & .toast-header {
    justify-content: space-between;
  }

  & button {
    margin: 0 !important;
  }

  .errors-message_item {
    line-height: 14px;
    margin-bottom: 0.5rem;
  }
}
