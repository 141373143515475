.ContactInfo{
	font-size: 14px;
	font-weight: bold;
	.row{
		padding: 10px 0px;
	}
	.gray-12px{
		font-size: 12px;
		font-weight: normal;

		line-height: 18px;

		color: #707070;
	}
}
.ContactPhone{
	font-size: 30px;
	line-height: 36px;
}
.mainpage-map{
	height: 350px;
}
