/* Footer styles */
footer{
	position: absolute;
	width: 100%;

	bottom: -86px;
	line-height: 36px;

	background: #962932;

	.nav{
		a.nav-link{
			color: white;
			padding: 0px 10px;
		}
		a.nav-link:hover{
			color: #B11622;
			background: #fff;
			transition: all 0.1s ease;
		}
	}
	p{
		color: white;
	}
}
/* ------------------------ */
