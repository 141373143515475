/* big icons */
.newicon{
	width: 60px;
	height: 50px;
	background-size: cover;

	cursor: pointer;

	transition: 0.2s ease-out;
}
.mainmenu{
	background-image: url(../static/images/icons/icon-menu.svg);

	&:hover{
		background-image: url(../static/images/icons/hovered/icon-menu.svg);
	}
}
.mainmenu-white{
	background-image: url(../static/images/icons/icon-menu-white.svg);

	&:hover{
		background-image: url(../static/images/icons/hovered/icon-menu.svg);
	}
}
.mainmenu-blue{
	background-image: url(../static/images/icons/icon-menu-blue.svg);

	&:hover{
		background-image: url(../static/images/icons/icon-menu.svg);
	}
}
.search{
	background-image: url(../static/images/icons/icon-search.svg);

	&:hover{
		background-image: url(../static/images/icons/hovered/icon-search.svg);
	}
}
.profile{
	background-image: url(../static/images/icons/icon-profile.svg);

	&:hover{
		background-image: url(../static/images/icons/hovered/icon-profile.svg);
	}
}
.cart{
	background-image: url(../static/images/icons/icon-cart.svg);

	&:hover{
		background-image: url(../static/images/icons/hovered/icon-cart.svg);
	}
}
/* contacts icons */
.contacticon{
	width: 64px;
	height: 56px;

	background-size: cover;

	transition: 0.2s ease-out;
}
#iconMessage{
	background-image: url(../static/images/icons/icon-message.svg);

	&:hover{
		background-image: url(../static/images/icons/hovered/icon-message.svg);
	}
}
#iconLocation{
	background-image: url(../static/images/icons/icon-location.svg);

	&:hover{
		background-image: url(../static/images/icons/hovered/icon-location.svg);
	}
}
#iconSkype{
	background-image: url(../static/images/icons/icon-skype.svg);

	&:hover{
		background-image: url(../static/images/icons/hovered/icon-skype.svg);
	}
}
#iconClock{
	background-image: url(../static/images/icons/icon-clock.svg);

	&:hover{
		background-image: url(../static/images/icons/hovered/icon-clock.svg);
	}
}
