.cart-header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  padding-top: 20px;
  background-color: white;

  &.sticky {
    position: sticky;
    top: 0;
  }

  &_btns {
    display: flex;
    align-items: center;
  }

  .cart-title,
  .order-title {
    transition: 0.15s;
    opacity: 0.4;
  }

  .cart-title.active,
  .order-title.active {
    color: var(--main-red);
    opacity: 1;
  }
}

.cart-page {
  .checkbox-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 15px;
  }

  .table-popover {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 15px;
    cursor: help;
  }

  .del-item {
    text-align: center;
    background-color: white;
    cursor: pointer;
  }

  .border-color {
    border-color: transparent !important;
  }

  .close-item:hover {
    color: #962932;
  }

  .plus-minus-btn {
    width: 25px;
    border-width: 0;
    background-color: transparent;
    cursor: pointer;
  }

  .plus-minus-btn:disabled {
    cursor: default;
  }

  .comment-btn {
    max-width: 200px;
    padding: 0.75rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.order-page {
  display: grid;
  grid-template-columns: 400px 1fr;
  column-gap: 3rem;

  .prod-descr {
    place-self: center;
  }

  .custom-label {
    display: flex;
    justify-content: space-between;

    .set-default-btn.disable {
      text-decoration: none;
    }

    .set-default-btn {
      margin: 0;
      padding: 0;
      border: none;
      border-radius: 0;
      background-color: transparent;
      text-decoration: underline;
    }
  }

  .custom-input {
    border-radius: 0;
  }

  .agree-area_descr {
    display: block;
    padding-left: 1.25rem;
    font-size: 14px;
    line-height: 16px;
    color: gray;
  }

  .grid-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
  }

  [data-reach-combobox-input] {
    border-radius: 0;
    border: 1px solid var(--main-red);
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out;
  }

  [data-reach-combobox-input]:focus-visible,
  [data-reach-combobox-input]:focus,
  [data-reach-combobox-input]:active {
    border: 1px solid var(--main-red) !important;
    border-radius: 0 !important;
    outline: none !important;
  }

  [data-reach-combobox-input]:disabled {
    background-color: #e9ecef;
  }
}

input.no-spin::-webkit-outer-spin-button,
input.no-spin::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

input.no-spin[type='number'] {
  -moz-appearance: textfield;
}

@media (max-width: 860px) {
  .order-page {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 861px) {
  // .prod-descr {
  //   grid-column: 2/3;
  // }
  .user-area {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .user-comment {
    grid-column: 1/2;
  }
  .delivery {
    grid-column: 1/2;
  }
  .delivery-options {
    grid-column: 2/3;
    grid-row: 1/3;
  }
  .agree-area {
    grid-column: 2/3;
    grid-row: 1/3;
    &.down {
      grid-row: 3/4;
    }
  }
  .grid-wrapper {
    grid-column: 2/3;
  }
}

.server-error {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 0 25px 25px 0;
  padding: 15px;
  color: white;
  background-color: red;
  z-index: 10;
}

.server-loading {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 15px;
  z-index: 10;
}

.comment,
.comment:focus {
  border-color: var(--border) !important;
}

.cart-title:hover {
  opacity: 1;
}
.to-cart:hover {
  color: var(--main-red);
  text-decoration: underline;
  cursor: pointer;
}

.toast-validation-error {
  // position: fixed;
  // bottom: 30px;
  // left: 30px;
  border-radius: 0;
  position: absolute;
  bottom: calc(100% + 30px);
  left: 50%;
  transform: translateX(-50%);

  & .toast-header {
    justify-content: space-between;
  }

  & button {
    margin: 0 !important;
  }

  .errors-message_item {
    line-height: 14px;
    margin-bottom: 0.5rem;
  }
}
