#firstParagraph{
  &:hover{
    color: #b11622;
    cursor: pointer;
  }
}

.navProfileWrapper{
  display: flex;
  font-size: 1.2rem;
  img{
    width: 27px;
    height: 27px;
  }
  .navProfileLink{
    padding-left: 5px;
  }
}

.navProfileDropDown{
  margin-left: 30px;
}
